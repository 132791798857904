.quiz-question__back {
    background-image: url(/images/girl-smile.png);
    background-position: 70% 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.quiz-container {
    background: #F5F5F5;
    margin: 100px auto !important;
    height: 600px;
    border-radius: 10px;
    padding: 0px 50px 0 50px;
    position: relative;
}

.quiz-quesion__heading__text {
    color: #301EA1;
    font-size: 36px;
    text-align: left;
}

.grid-container-quiz {
    background: #FFF;
}

@media only screen and (max-width: 960px) {
    .quiz-question__back {
        display: none;
    }
    .quiz-container {
        height: fit-content;
        margin: 0 !important;
        border-radius: 0;
        padding: 50px 16px 0 16px;
        position: inherit;
    }
    .quiz-quesion__heading__text {
        font-size: 24px;
    }

    .grid-container-quiz {
        background: #F5F5F5;
    }
}
