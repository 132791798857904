.mobileVersion {
    flex-direction: column;
    justify-content: center;
    display: none;
    text-align: center;
}

.desktopVersion {
    display: block;
}

@media only screen and (max-width: 600px) {
    .desktopVersion {
        display: none;
    }
    .mobileVersion {
        display: flex;
    }
}