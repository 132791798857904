/* @import url('https://fonts.googleapis.com/css?family=Poppins'); */

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Poppins', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
  overflow-x: hidden;
  overflow-y: hidden;
}

header {
  padding: 0 !important;
}

.MuiDialogActions-root {
  justify-content: center !important;
}

body .phone-input {
  border: 1px solid #e2e2e1;
  overflow: hidden;
  border-radius: 4px;
  background-color: #fff;
  transition: all 1s;
}

body .phone-input:hover {
  background-color: #fff;
}

body .phone-input:focus {
  background-color: #fff;
  box-shadow: #fff 0 0 0 2px;
  border-color: #333;
}

body .MuiFilledInput-root {
  background-color: #fcfcfb;
}

h1 {
  font-size: 35px;
  width: 80%;
  font-family: 'Poppins';
  line-height: 1.2;
}

.show-mobile {
  display: none;
}

@media screen and (max-width: 1000px) {
  h1 {
    font-size: 24px;
    line-height: 1.4;
    margin-top: 34px;
    width: 100%;
    margin-bottom: 0px;
  }

  .hide-mobile {
    display: none;
  }

  .show-mobile {
    display: block;
  }

  .flex-column-mobile {
    flex-direction: column;
  }
}
