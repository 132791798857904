.search_button {
    flex: 1;
    margin: 7px 24px !important;
    height: 60 !important;
    background-color: #0D1C86 !important;
    color: white !important;
}

.search_button1 {
    flex: 1;
    margin: 7px 24px !important;
    height: 60 !important;
    background-color: #FDED5C !important;
    color: black !important;
}

.form__width {
    width: 600px;
    margin: 0 auto;
}

@media only screen and (max-width: 875px) {
    .search_button {
        margin: 0 !important;
    }
    .search_button1 {
        margin: 0 !important;
    }
    .form__width {
        width: 100%;
    }
}