.leftVerified {
    margin-top: 20px;
    display: none;
}

@media only screen and (max-width: 959px) {
    .rightCol {
        display: none;
    }
}

@media only screen and (max-width: 600px) {

    .leftVerified {
        display: block;
    }
}
