h2 {
    text-align: center;
    font-size: 2em;
    color: black;
}

h4 {
    color: black;
}

strong {
    color: black;
}

li {
    padding: 0.2em;
}