.smile_back {
    position: absolute;
    top: 81px;
    height: 502px;
    width: auto;
    right: 0;
    z-index: -1;
}

.dentist_back {
    position: absolute;
    top: 110px;
    height: 400px;
    width: auto;
    right: 0;
    z-index: -1;
}

.no-margin {
    margin-left: auto;
    margin-right: auto;
}

.numberWhite {
    background-color: rgba(255,255,255,0.1);
    width: 50px;
    height: 50px;
    font-size: 24px;
    text-align: center;
    border-radius: 50%;
    line-height: 2;
}

.button_signup {
    width: 200px;
    height: 60px;
    background-color: #0D1C86 !important;
    color: white !important;
}

.topNewgal {
    display: none;
}

.bottomNewgal {
    display: block;
}

.center_sign_provider {
    width: 600px;
    margin: 0 auto;
}

.button_provider_start {
    height: 58px !important;
    margin: 8px 20px !important;
}

@media only screen and (max-width: 1000px) {
    .bottomNewgal {
        display: none;
    }
    
    .topNewgal {
        display: block;
    }

    .smile_back {
        position: relative;
        width: calc(100% + 50px);
        height: auto;
        top: inherit;
        margin-left: -25px;
        margin-right: -25px;
    }

    .dentist_back {
        position: relative;
        width: calc(100% + 50px);
        height: auto;
        top: 64px;
        margin-left: -25px;
        margin-right: -25px;
    }

    .white_card {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: inherit !important;
        margin-top: 200px;
    }
}

@media only screen and (max-width: 550px) {
    .button_signup {
        width: 100%;
    }

    .center_sign_provider {
        width: 100%;
        flex-direction: column;
    }

    .button_provider_start {
        margin: auto !important;
        width: 100%;
    }

    .no-margin {
        margin-left: -25px !important;
        margin-right: -25px !important;
    }
}

@media only screen and (min-width: 1400px) {
    .smile_back {
        right: calc(50vw - 750px)
    }
}