.radio_button {
    width: 250px;
    padding-left: 20px;
    background: rgba(178, 170, 224, 0.12);
}

.radio_image_button {
    width: 260px;
}

.tooth_image {
    width: 250px;
    height: 150px;
}

.navigation_menu {
    position: absolute;
    bottom: 0;
    background: #F5F5F5;
    width: calc(100% - 50px);
    border-top: solid;
    margin-left: -50px;
    border-color: #C4C4C4;
    border-width: thin;
    padding: 10px 25px;
}

.next_button {
    background-color: #0D1C86 !important;
    color: white !important;
    width: 150px;
    float: right;
}

.previous_button {
    background-color: white !important;
    color: black !important;
    width: 150px;
    display: inline-flex;
    float: left;
}

.other_provider {
    font-size: 20px;
    height: 45px;
    border: solid;
    border-width: thin;
    padding-left: 10px;
}

.quiz-content {
    height: fit-content;
    overflow: inherit;
}

@media only screen and (min-width: 960px) {
    .quiz-content {
        height: 400px;
        overflow: auto;
    }
}

@media only screen and (min-width: 550px) and (max-width: 960px) {
    .navigation_menu {
        position: fixed;
        width: calc(100% - 50px);
        margin-left: -16px;
    }
}

@media only screen and (max-width: 550px) {
    .other_provider {
        width: 100%;
    }

    .navigation_menu {
        position: fixed;
        width: calc(100% - 50px);
        margin-left: -16px;
    }

    .previous_button {
        display: none !important;
    }

    .next_button {
        width: 100%;
        float: inherit;
    }

    .radio_button {
        width: inherit;
    }

    .radio_image_button {
        width: inherit;
    }

    .radio_grid {
        width: 98%;
    }

    .tooth_image {
        width: 97%;
        height: auto;
    }
}
