a {
    text-decoration: none;
    color: white;
    width: 130px;
    text-align: center;
    font-weight: bolder;
}

.sectionDesktop {
    display: flex;
}

.sectionMobile {
    display: none;
}

@media only screen and (max-width: 850px) {
    .sectionDesktop {
        display: none;
    }

    .sectionMobile {
        display: flex;
    }
}