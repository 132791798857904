.gray_paragraph {
    color: gray;
    font-size: 14px;
    margin: 0;
    padding: 0;
}

.save_button {
    position: absolute !important;
    right: 0px;
    top: 0px;
    color: white !important;
    background-color: #0D1C86 !important;
}
