.total_container {
  position: relative;
  box-sizing: border-box;
  padding-bottom: 0 !important;
}

@media screen and (max-width: 600px) {
  .total_container {
    padding-top: 60px !important;
  }
}